.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.smaller-input {
  font-size: 10px;
  padding: 2px 5px;
}
.text-danger {
  font-size: 12px;
  font-weight: bold;
  padding: 2px 5px;
  color: red;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 2px;
  padding-left: 2px;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0.25rem;
  margin-bottom: 20px;
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 550px;
  overflow: auto;
}

.MuiCardHeader-root{
  border-bottom:1px solid #1f3e98;
  background-color: #DBE2EA;
}
.Boxsearch{
  border-top: 1px dotted #999;
  border-bottom: 1px dotted #999;
  background-color:  #f5f5f5;
}
.table td, .table th{
  vertical-align: middle;
}
.table-responsive {
  height: 190px;
}

.MuiCardHeader-title {
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 800;
  line-height: 1.334;
  letter-spacing: 0em;
}
